/** @format */

import { DB_RESPONSE_ERROR, DB_RESPONSE_OK } from "constants/dbStatuses";
import Dexie from "dexie";
import moment from "moment/moment";
import { uniquesByTicketIdAndScanned } from "util/arrays";

export const db = new Dexie("liveto-scanner-v2");

// NOTE: Don’t declare all columns like in SQL.
// You only declare properties you want to index, that is properties you want to use in a where() or get() query.
//
// ++	    Auto-incremented primary key
// &	    Unique
// *	    Multi-entry index
// [A+B]	Compound index
// https://dexie.org/docs/Version/Version.stores()
db.version(30).stores({
	configuration: "&apikey",
	booth: "&apikey",
	userSettings: "++id",
	scanSettings: "++id",
	scanMode: "++id",
	offlinetickets: "++id,ticket_id",
	tickets: "&ticket_code,&id",
	scans: "&id,ticket_id,device_label",
	events: "&slug",
});

/*
statuses: 
100 - success
200 - error
*/

function dbResponse(status, data) {
	let response = { status: status, timestamp: moment().format("YYYY-MM-DDTHH:mm:ss") };
	if (data) {
		response["data"] = data;
	}
	return response;
}

export async function getOfflineTicketsAmountFromDatabase() {
	try {
		await db.open();
		const offlinetickets = await db.offlinetickets.toArray();
		return dbResponse(100, offlinetickets.length);
	} catch (err) {
		console.error("err", err);
		return dbResponse(200, err);
	}
}

export async function writeOfflineTicketToDatabase(ticketData) {
	try {
		await db.open();
		await db.offlinetickets.add(ticketData);
		return dbResponse(DB_RESPONSE_OK, ticketData);
	} catch (err) {
		console.log(err);
		return dbResponse(DB_RESPONSE_ERROR, null);
	}
}

const defaultScanSettingsTemplate = {
	scanDelay: 3000,
	keepScanPopupOpen: false,
	logging: false,
	autoUpdate: true,
	updateInterval: 30000,
};

// apikey is the primary key for the records.
export async function getScanSettingsFromDatabase(initialValue) {
	try {
		await db.open();

		const data = await db.scanSettings.orderBy().first();

		if (!data) {
			await db.scanSettings.put(initialValue);
			return dbResponse(DB_RESPONSE_OK, initialValue);
		}

		return dbResponse(DB_RESPONSE_OK, data);
	} catch (err) {
		console.log(err);
		return dbResponse(DB_RESPONSE_ERROR, null);
	}
}

export async function getEventsRecordsFromDatabase() {
	try {
		await db.open();
		const events = await db.events.toArray();
		return dbResponse(DB_RESPONSE_OK, events);
	} catch (err) {
		return dbResponse(DB_RESPONSE_ERROR, null);
	}
}

export async function getScannedTicketsRecordsFromDatabase() {
	try {
		await db.open();
		const start = Date.now();
		const results = await db.transaction("rw", [db.tickets, db.scans, db.offlinetickets], async () => {
			const scans = await db.scans.toArray();
			const uniquesByLatestScan = uniquesByTicketIdAndScanned(scans);
			const offlineScans = await db.offlinetickets.toArray();

			const uniqueScans = [...new Set([...uniquesByLatestScan, ...offlineScans].map(s => s.ticket_id))];
			let uniqueTickets = [];
			for (let key of uniqueScans) {
				const data = await db.tickets.where("id").equals(key).toArray();
				if (data.length > 0) uniqueTickets.push(data[0]);
			}
			return uniqueTickets;
		});

		const end = Date.now();

		return dbResponse(DB_RESPONSE_OK, results);
	} catch (err) {
		console.log(err);
		return dbResponse(DB_RESPONSE_ERROR, null);
	}
}

export async function getBoothDataFromDatabase(apikey) {
	try {
		await db.open();
		const data = await db.booth.where("apikey").equals(apikey).first();
		return dbResponse(DB_RESPONSE_OK, data);
	} catch (err) {
		console.log(err);
		return dbResponse(DB_RESPONSE_ERROR, null);
	}
}

export async function getLanguageSelectionFromDatabase() {
	try {
		const data = await db.userSettings.toArray();
		if (data.length > 0) {
			return dbResponse(DB_RESPONSE_OK, data[0].language);
		}

		// Fallback if no language has been set
		return dbResponse(DB_RESPONSE_OK, "fi");
	} catch (err) {
		console.log(err);
		return dbResponse(DB_RESPONSE_ERROR, null);
	}
}

export async function getUserSettingsFromDatabase(initialValue) {
	try {
		await db.open();
		const data = await db.userSettings.orderBy().first();

		if (!data) {
			await db.userSettings.put(initialValue);
			return dbResponse(DB_RESPONSE_OK, initialValue);
		}

		return dbResponse(DB_RESPONSE_OK, data);
	} catch (err) {
		console.log(err);
		return dbResponse(DB_RESPONSE_ERROR, null);
	}
}

export async function modifyUserSettingRecordInDatabase(id, key, value) {
	try {
		await db.open();
		const modifyData = { [key]: value };
		await db.userSettings.where({ id: id }).modify(modifyData);
		const responseData = await db.userSettings.orderBy().first();
		return dbResponse(DB_RESPONSE_OK, responseData);
	} catch (err) {
		console.log(err);
		return dbResponse(DB_RESPONSE_ERROR, null);
	}
}

export async function modifyScanSettingsRecordInDatabase(id, key, value) {
	try {
		await db.open();
		const modifyData = { [key]: value };
		await db.scanSettings.where({ id: id }).modify(modifyData);

		const responseData = await db.scanSettings.orderBy().first();
		return dbResponse(DB_RESPONSE_OK, responseData);
	} catch (err) {
		console.log(err);
		return dbResponse(DB_RESPONSE_ERROR, null);
	}
}

export async function modifyScanModeOptionsInIndexedDb(id, values) {
	try {
		await db.open();
		const modifyData = { ...values };
		await db.scanMode.where({ id: id }).modify(modifyData);

		const responseData = await db.scanMode.orderBy().first();
		return dbResponse(DB_RESPONSE_OK, responseData);
	} catch (err) {
		console.log(err);
		return dbResponse(DB_RESPONSE_ERROR, null);
	}
}

export async function writeTicketsToIndexedDb(tickets = []) {
	try {
		await db.open();

		if (tickets.length > 0) {
			await db.tickets.bulkPut(tickets);
		}

		const records = await db.tickets.toArray();
		let queryResponse = { ...dbResponse(100, records), queried: tickets.length };
		return queryResponse;
	} catch (err) {
		console.error("err", err);
		return dbResponse(200, err);
	}
}

export async function getScansAmountFromDatabase() {
	try {
		await db.open();
		const scans = await db.scans.toArray();
		return dbResponse(100, scans.length);
	} catch (err) {
		console.error("err", err);
		return dbResponse(200, err);
	}
}

export async function getTicketsAmountFromDatabase() {
	try {
		await db.open();
		const tickets = await db.tickets.toArray();
		return dbResponse(100, tickets.length);
	} catch (err) {
		console.error("err", err);
		return dbResponse(200, err);
	}
}

export async function getTicketListFromDatabase() {
	try {
		await db.open();
		const tickets = await db.tickets.toArray();
		return dbResponse(100, tickets);
	} catch (err) {
		console.error("err", err);
		return dbResponse(200, err);
	}
}

export async function getUniqueScansAmountFromDatabase() {
	try {
		await db.open();
		const scans = await db.scans.toArray();
		const uniqueScans = [...new Set([...scans].map(s => s.ticket_id))].length;
		return dbResponse(100, uniqueScans);
	} catch (err) {
		console.error("err", err);
		return dbResponse(200, err);
	}
}

function findVariantFromProducts(id, products) {
	for (let product of products) {
		for (let variant of product.variants) {
			if (variant.id === id) {
				const productData = { ...product };
				delete productData.variants;

				return {
					product: productData,
					variant: variant,
				};
			}
		}
	}
}

export async function getTicketData(code) {
	try {
		await db.open();
		const ticket = await db.tickets.get({ ticket_code: code });
		if (!ticket) {
			return dbResponse(DB_RESPONSE_ERROR, null);
		}
		const allOfflineScans = await db.offlinetickets.where({ ticket_id: ticket.id }).toArray();
		const allSyncedScans = await db.scans.where({ ticket_id: ticket.id }).toArray();
		const event = await db.events.get({ slug: ticket.event_slug });
		const productData = findVariantFromProducts(ticket.product_id, event.products);

		const totalScanHistoryForTicket = [...allSyncedScans, ...allOfflineScans].sort(
			(a, b) => moment(a.scanned).format("YYYYMMDD") - moment(b.scanned).format("YYYYMMDD")
		);
		return dbResponse(DB_RESPONSE_OK, {
			history: totalScanHistoryForTicket,
			productData: productData,
		});
	} catch (err) {
		return dbResponse(DB_RESPONSE_ERROR, err);
	}
}

export async function getBoothscanDataFromDatabase(code, apikey) {
	try {
		await db.open();
		const ticket = await db.tickets.get({ ticket_code: code });
		const allOfflineScans = await db.offlinetickets.where({ ticket_id: ticket.id, scan_type: "booth" }).toArray();
		const allSyncedScans = await db.scans.where({ ticket_id: ticket.id, scan_type: "booth" }).toArray();

		const scanned_times = [...allOfflineScans, ...allSyncedScans].filter(s => s.metadata.apikey === apikey).length;

		if (ticket) {
			return dbResponse(DB_RESPONSE_OK, {
				ticket: ticket,
				scanned_times,
			});
		}
		return dbResponse(DB_RESPONSE_ERROR, null);
	} catch (err) {
		return dbResponse(DB_RESPONSE_ERROR, null);
	}
}

export async function getTicketValidationDataFromDatabase(code, mode, section) {
	try {
		await db.open();

		const data = await db.transaction("rw", db.tickets, db.offlinetickets, db.scans, db.events, async () => {
			const ticket = await db.tickets.get({ ticket_code: code });
			// Ticket not found, no need for further handling
			if (!ticket) return null;

			// Return all entry scans that includes entries and exits
			let scanTypes = mode === "entry" || mode === "exit" ? ["entry", "exit"] : ["section"];

			const allOfflineScans = await db.offlinetickets.where({ ticket_id: ticket.id }).toArray();
			const allSyncedScans = await db.scans.where({ ticket_id: ticket.id }).toArray();

			const scans = await db.scans
				.where({ ticket_id: ticket.id })
				.filter(scan => scanTypes.includes(scan.scan_type) && scan.section_id === section)
				.toArray();
			const offlineScans = await db.offlinetickets
				.where({ ticket_id: ticket.id })
				.filter(s => scanTypes.includes(s.scan_type) && s.section_id === section)
				.toArray();

			// filter all scannings from offfline and stored scans that equals the current scanning options
			//const scans = allSyncedScans.filter(s => scanTypes.includes(s.scan_type) && s.section_id === section);
			//const offlineScans = allOfflineScans.filter(s => scanTypes.includes(s.scan_type) && s.section_id === section);

			const event = await db.events.get({ slug: ticket.event_slug });
			const productData = findVariantFromProducts(ticket.product_id, event.products);

			const totalScanHistoryForTicket = [...allSyncedScans, ...allOfflineScans].sort(
				(a, b) => moment(a.scanned).format("YYYYMMDD") - moment(b.scanned).format("YYYYMMDD")
			);
			const scanHistoryForTargetArea = [...scans, ...offlineScans].sort(
				(a, b) => moment(a.scanned).format("YYYYMMDD") - moment(b.scanned).format("YYYYMMDD")
			);

			let responseData = {
				ticketData: ticket,
				scanHistoryForTargetArea: scanHistoryForTargetArea,
				productData: productData,
				totalScanHistoryForTicket: totalScanHistoryForTicket,
			};

			// User is querying section instead of entry / exit
			if (section) {
				const sectionData = event?.venuemap?.sections.find(sec => sec.id === section) || null;
				const accesscontrol = sectionData
					? event?.access_controls.find(ac => ac.id === sectionData.access_control)
					: null;
				responseData["accesscontrol"] = accesscontrol;
				responseData["sectionName"] = sectionData.name;
			} else {
				const venueAccessControlId = event?.venuemap?.access_control || null;
				const accesscontrol = venueAccessControlId
					? event?.access_controls.find(ac => ac.id === venueAccessControlId)
					: null;
				responseData["accesscontrol"] = accesscontrol;
			}
			return responseData;
		});
		if (data === null) {
			return dbResponse(DB_RESPONSE_ERROR, null);
		}
		return dbResponse(100, data);
	} catch (err) {
		console.error("err", err);
		return dbResponse(200, err);
	}
}

export async function updateTicketDataFromBackendToIndexedDb() {}

export async function getLastIdsForTicketsApiCall() {
	try {
		await db.open();
		const lastTicketRecord = await db.tickets.orderBy("id").last();
		const lastScanRecord = await db.scans.orderBy("id").last();
		return dbResponse(100, {
			lastTicketId: lastTicketRecord.id,
			lastScanId: lastScanRecord.id,
		});
	} catch (err) {
		console.error("err", err);
		return dbResponse(200, err);
	}
}

/*
    For requesting necessary data to update tickets & scans to indexed db that need to be fetched from backend

*/
export async function requestTicketUpdateDataFromIndexedDb() {
	try {
		await db.open();
		const lastTicketRecord = await db.tickets.orderBy("id").last();
		const lastScanRecord = await db.scans.orderBy("id").last();
		const configuration = await db.configuration.toArray();
		const offlineTickets = await db.offlinetickets.toArray();

		return dbResponse(DB_RESPONSE_OK, {
			lastScanned: lastScanRecord?.id,
			lastTicket: lastTicketRecord?.id,
			configuration: configuration[0],
			offlineTickets: offlineTickets,
		});
	} catch (err) {
		console.error("err", err);
		return dbResponse(200, err);
	}
}

export async function requestLastScanIdFromIndexedDb() {
	try {
		await db.open();
		const lastScanId = await db.scans.orderBy("id").last();
		return dbResponse(DB_RESPONSE_OK, lastScanId);
	} catch (err) {
		console.error("err", err);
		return dbResponse(200, err);
	}
}

export async function getOfflineTicketsFromIndexedDb() {
	try {
		await db.open();

		const offlineTickets = await db.offlinetickets.toArray();

		return dbResponse(DB_RESPONSE_OK, offlineTickets);
	} catch (err) {
		console.error("err", err);
		return dbResponse(200, err);
	}
}

export async function clearOfflineTicketsFromIndexedDb() {
	try {
		await db.open();
		const offlineTickets = await db.offlinetickets.clear();

		return dbResponse(DB_RESPONSE_OK, null);
	} catch (err) {
		console.log(err);
		return dbResponse(DB_RESPONSE_ERROR, err);
	}
}

export async function writeScansToIndexedDb(scans = []) {
	try {
		await db.open();

		if (scans.length > 0) {
			await db.transaction("rw", db.scans, async () => {
				await db.scans.bulkPut(scans);
			});
		}
		const records = await db.scans.toArray();
		return dbResponse(100, records);
	} catch (err) {
		console.error("err", err);
		return dbResponse(200, err);
	}
}

export async function getAllBoothScansByDeviceLabelFromIndexedDb(device_label) {
	try {
		await db.open();
		const scans = await db.scans.where("device_label").equals(device_label).toArray();
		return dbResponse(DB_RESPONSE_OK, scans);
	} catch {
		return dbResponse(DB_RESPONSE_ERROR, null);
	}
}

// To request for amount of unique scans.
//Scans amount can be greater that the ticket amount, since 1 ticket can be scanned multiple times
export async function getUniqueScannedTicketsAmount() {
	try {
		await db.open();
		const scans = await db.scans.toArray();
		const uniqueScans = [...new Set([...scans].map(s => s.ticket_id))].length;
		return dbResponse(DB_RESPONSE_OK, uniqueScans);
	} catch (err) {
		console.error(err);
		return dbResponse(DB_RESPONSE_ERROR);
	}
}

export async function getTicketScanCurrentState() {
	try {
		await db.open();
		const scans = await db.scans.toArray();
		const uniqueScans = [...new Set([...scans].map(s => s.ticket_id))].length;
		const ticketsAmount = await db.tickets.count();

		return dbResponse(DB_RESPONSE_OK, {
			tickets: ticketsAmount,
			scans: uniqueScans,
		});
	} catch (err) {
		console.error(err);
		return dbResponse(DB_RESPONSE_ERROR);
	}
}

export async function removeAllDataFromDatabase() {
	try {
		await db.open();
		await db.delete();

		return dbResponse(DB_RESPONSE_OK, null);
	} catch (err) {
		console.error(err);
		return dbResponse(DB_RESPONSE_ERROR, null);
	}
}

// For persisting a configuration that is fetched from the backend to indexedDb.
// If a record already exists, overrides changed fields, otherwise adds a record.
export async function setConfigurationToDatabase({ configuration }) {
	try {
		await db.open();
		const data = await db.transaction("rw", db.configuration, db.booth, db.events, async () => {
			// Configuration handling
			const configurationData = {
				apikey: configuration.apikey,
				title: configuration.title,
				expiration_time: configuration.expiration_time,
				events: configuration.events,
			};
			await db.configuration.put({ ...configurationData });

			if (configuration.booth_config) {
				await db.booth.put({ ...configuration.booth_config, apikey: configuration.apikey });
			}

			// Event data handling
			if (configuration.event_data) {
				const eventSlugs = Object.keys(configuration.event_data);
				for (let slug of eventSlugs) {
					if (configuration.event_data[slug]) {
						await db.events.put({ ...configuration.event_data[slug], slug: slug });
					}
				}
			}

			const scannerConfigurationData = await db.configuration.get({ apikey: configuration.apikey });
			const eventData = await db.events.toArray();
			const boothData = await db.booth.get({ apikey: configuration.apikey });

			return {
				configuration: scannerConfigurationData,
				events: eventData,
				boothOptions: boothData,
			};
		});

		return dbResponse(100, data);
	} catch (err) {
		console.error(err);
		return dbResponse(200, null);
	}
}

// Returns the current configuration set in the scanner
export async function getScannerConfigurationFromDatabase() {
	try {
		await db.open();
		const list = await db.configuration.toArray();
		if (list && list[0]) {
			return dbResponse(100, list[0]);
		}

		return dbResponse(200, null);
	} catch (err) {
		console.error(err);
		return dbResponse(200, null);
	}
}

export async function getScanModeOptionsFromIndexedDb(initialValue) {
	try {
		await db.open();
		const data = await db.scanMode.orderBy().first();
		if (!data) {
			await db.scanMode.put(initialValue);
			return dbResponse(DB_RESPONSE_OK, initialValue);
		}

		return dbResponse(DB_RESPONSE_OK, data);
	} catch (err) {
		console.log(err);
		return dbResponse(DB_RESPONSE_ERROR, null);
	}
}

export async function clearIndexedDbTable(table = "") {
	try {
		await db.open();
		const data = await db[table].clear();
		return dbResponse(DB_RESPONSE_OK, data);
	} catch (err) {
		console.log(err);
		return dbResponse(DB_RESPONSE_ERROR, null);
	}
}

export async function repopulateScansAndTicketsInIndexedDB(tickets = [], scans = [], addScans = false) {
	try {
		await db.open();
		await db.tickets.clear();
		await db.tickets.bulkPut(tickets);
		if (addScans) {
			await db.scans.clear();
			await db.scans.bulkPut(scans);
		}
		return dbResponse(DB_RESPONSE_OK, null);
	} catch (err) {
		console.log(err);
		return dbResponse(DB_RESPONSE_ERROR, null);
	}
}

export async function checkForNewTicketsAndScans(tickets = 0, scans = 0, checkScans = false) {
	try {
		await db.open();
		const ticketCount = await db.tickets.count();
		const newTickets = tickets - ticketCount;
		let newScans = null;
		if (checkScans) {
			const scanCount = await db.scans.count();
			newScans = scans - scanCount;
		}
		return dbResponse(DB_RESPONSE_OK, { newTickets, newScans });
	} catch (err) {
		console.log(err);
		return dbResponse(DB_RESPONSE_ERROR, null);
	}
}
