/** @format */

import QRReader from "Components/qr-reader/scanner";
import { useCallback, useContext, useRef, useState } from "react";
import { hapticFeedback } from "util/vibrate";

import "./scan.scss";
import ExtraInfo from "./extraInfo";
import { ConfigurationsContext } from "Context/configurations";
import { getBoothscanDataFromDatabase } from "indexedDb/dexie";
import { DB_RESPONSE_OK } from "constants/dbStatuses";
import moment from "moment";
import { useParams, useHistory } from "react-router";

const BoothScan = () => {
	const { scanSettings } = useContext(ConfigurationsContext);

	const [scanData, setScanData] = useState(null);
	const [scannedTimes, setScannedTimes] = useState(0);

	const { apikey } = useParams();

	const readerRef = useRef();

	const scanDisabledRef = useRef();
	scanDisabledRef.current = scanData !== null ? true : false;

	const scanTemplate = (ticketId, device) => {
		return {
			ticket_id: ticketId,
			scanned: moment().format("YYYY-MM-DDTHH:mm:ssZ"),
			scan_type: "booth", // [entry, exit], section, booth
			section_id: null, // section id (number), null
			device_id: device,
			device_label: device,
			metadata: {},
		};
	};

	async function getTicketData(code) {
		const { status, data } = await getBoothscanDataFromDatabase(code, apikey);
		if (status === DB_RESPONSE_OK) {
			const { ticket, scanned_times } = data;
			setScannedTimes(scanned_times);
			const ticketData = scanTemplate(ticket.id, scanSettings.device_name);
			return { flag: 2, data: ticketData };
		}
		return { flag: 0, data: null };
	}

	const handleScan = useCallback(
		async (err, result) => {
			if (!scanDisabledRef.current && result) {
				scanDisabledRef.current = true;
				hapticFeedback([200]);
				const data = await getTicketData(result.text);
				setScanData(data);
			}
		},
		[scanData]
	);

	const scanIsBlocked = scanDisabledRef.current ? true : false;

	return (
		<div id="booth-scan">
			<div className={`scanner`}>
				{!scanData ? (
					<QRReader
						onUpdate={handleScan}
						delay={500}
						ref={readerRef}
						blocked={scanIsBlocked}
						width={"100%"}
						height={"100%"}
					/>
				) : (
					<ExtraInfo scanData={scanData} setScanData={setScanData} scannedTimes={scannedTimes} />
				)}
			</div>
		</div>
	);
};

export default BoothScan;
